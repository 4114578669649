import { NgModule } from '@angular/core';
import {BlogComponent} from './components/pages/blog/blog.component';
import {BlogRoutingModule} from './blog-routing.module';
import {SharedModule} from '../shared/shared.module';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
  declarations: [BlogComponent],
  imports: [
    SharedModule,
    BlogRoutingModule,
    YouTubePlayerModule,
    ClipboardModule,
  ]
})
export class BlogModule {}
