import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {delay, map, tap} from 'rxjs/operators';
import {Meta, Title} from '@angular/platform-browser';
import {ArticleService} from '../services/article.service';
import {environment} from '../../environments/environment';

export class MetaDataModel {
  title?: string;
  description?: string;
  media?: any[];
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoGuard implements CanActivate, CanActivateChild {
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private articleService: ArticleService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     console.log('canActivateChild SeoGuard');
    const metaTags:MetaDataModel = {
      title: environment.title+' - '+environment.slogan,
      description : environment.description,
      url: environment.baseUrl + state.url,
      media: []
    };

    if(next.params?.id){
      // Post
      const $req = this.articleService.get$(next.params?.id).pipe(map(response =>{
        // console.log('response',response.data);
        const post = response.data;
        metaTags.title = post.title + ' - '+environment.title+' / '+environment.slogan;
        metaTags.media = post.media;
        this.updateMetaTags(metaTags);
        return true;
      }));
      const $sub = $req.subscribe( ()=>{ $sub.unsubscribe() })
      return $req;
    }
    else{
      // Posts list (category view / homepage)
      const $req = this.articleService.list$({limit: 5}).pipe(map((response: {data:any[]}) =>{
        // console.log('response',response.data);
        for(const pk in response.data){
          if(response.data[pk]){
            const post = response.data[pk];
            if(post.media){
              metaTags.media = metaTags.media.concat(post.media)
            }
          }
        }
        this.updateMetaTags(metaTags);
        return true;
      }));
      const $sub = $req.subscribe( ()=>{ $sub.unsubscribe() });
      return $req;
    }
  }

  updateMetaTags(data: MetaDataModel){
    if(data.url){
      this.metaTagService.updateTag({ content: data.url} , 'property="og:url"' );
    }
    if(data.description){
      this.metaTagService.updateTag({ content: data.description} , 'name="description"' );
      this.metaTagService.updateTag({ content: data.description} , 'property="og:description"' );
    }
    if(data.title){
      this.metaTagService.updateTag({ content: data.title} , 'property="og:title"'  );
      this.titleService.setTitle(data.title);
    }
    if(data.media && data.media.length > 0){
      while(this.metaTagService.getTag('property="og:image"')){
        this.metaTagService.removeTag('property="og:image"');
      }

      for(const k in data.media){
        if(data.media[k]){
          const media = data.media[k];
          if(media.category === 'image'){
            this.metaTagService.addTag({property: 'og:image', content: environment.baseUrl + media.value + '&size=medium'});
          }
        }
      }
    }
  }
}
