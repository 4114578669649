import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {ListArticlesParameter} from '../_models/article/listArticlesParameter.model';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  public page = 1;

  constructor(
    private apiService: ApiService,
  ) {
  }

  public categories = [
    {slug: 'text', title: 'Text'},
    {slug: 'image', title: 'Image'},
    {slug: 'video', title: 'Video'},
    {slug: 'audio', title: 'Audio'},
    // {slug: 'music', title: 'Music'},
  ];

  public statuses = [
    {value: 'I', title: 'Inactive'},
    {value: 'A', title: 'Active'},
  ];

  public list$( params: ListArticlesParameter= {} ) {
    params = Object.assign({orderBy: '-id', page: 1, limit: 25}, params);
    // console.log('params', params);
    this.page = params.page;
    return this.apiService.get('blog/posts', params);
  }

  public get$(idOrSlug: number | string) {
    return this.apiService.get('blog/posts/' + idOrSlug);
  }

  public update$(idOrSlug: number | string, data) {
    return this.apiService.put('blog/posts/' + idOrSlug, data);
  }

  public addMedia$(id: number, args) {
    return this.apiService.post('blog/posts/' + id + '/media', args);
  }

  public removeMedia$(type: string, id: number) {
    return this.apiService.delete('blog/media/' + type + '/' + id);
  }

}
