import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MDBBootstrapModulesPro} from 'ng-uikit-pro-standard';
import {LightboxModule} from 'ngx-lightbox';
import {MomentPipe} from '../pipes/moment.pipe';
import {YoutubePipe} from '../pipes/youtube.pipe';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {SafeUrlPipe} from '../pipes/safe-url.pipe';
import {TrimPipe} from '../pipes/trim.pipe';
import { MediaComponent } from './components/media/media.component';
import { NgxTwitterWidgetsModule } from 'ngx-twitter-widgets';

const MODULES = [
  MDBBootstrapModulesPro.forRoot(),
  LightboxModule,
  CommonModule,
  NgxTwitterWidgetsModule,
];

const COMPONENTS = [
  //
];

const PIPES = [
  MomentPipe,
  YoutubePipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  TrimPipe
];


@NgModule({
  declarations: [...COMPONENTS, ...PIPES, MediaComponent],
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...PIPES, MediaComponent]
})
export class SharedModule { }
