import { NgModule } from '@angular/core';
import { ArticlesComponent } from './components/articles/articles.component';
import {SharedModule} from '../shared/shared.module';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {HomeComponent} from './components/home/home.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import {FormsModule} from '@angular/forms';

@NgModule({

  declarations: [ArticlesComponent, HomeComponent, SideNavComponent, TopNavComponent],
  imports: [
    SharedModule,
    DashboardRoutingModule,
    FormsModule,

  ]
})
export class DashboardModule { }
