import {Component, OnInit, ViewChild} from '@angular/core';
import {ArticleService} from '../../../services/article.service';
import {ArticleModel} from '../../../_models/article/article.model';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  loadingArticles = false;
  id: number | null = null;
  moment = moment;
  Posts: {
    data: ArticleModel[],
    current_page?: number,
    last_page?: number,
    from?: number,
    links?: {url: string, label: string, active: boolean}[]
  } = {data: []};

  Post: {
    data?: ArticleModel,
    loading: boolean,
    saving: boolean,
  } = {
    loading: false,
    saving: false,
  };

  // Attachable media
  mediaUrls = '';

  selectedFiles: FileList;

  addingMedia = false;
  uploadingFiles = false;

  @ViewChild('uploaderEl', {static: false}) uploaderEl: any;
  @ViewChild('EditForm', { static: false }) editFormEl: any;


  page = 1;
  categories = this.articleService.categories;
  statuses = this.articleService.statuses;

  constructor(
    private articleService: ArticleService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,

  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params?.id || null;
    });

  }

  ngOnInit(): void {
    if (this.id) {
      this.getArticle();
    } else {
      this.getArticles();

    }
  }

  getArticles() {
    this.loadingArticles = true;
    this.articleService.list$({page: this.page}).subscribe((response: any) => {
      if (!this.Posts || !this.Posts.data) {
        this.Posts = response;
      } else {
        this.Posts = response;
      }
      this.loadingArticles = false;

    });
  }

  getArticle() {
    this.Post.loading = true;
    this.articleService.get$(this.id).subscribe((response: {data: ArticleModel}) => {
      this.Post.data = response.data;
    });
  }

  changePage(label) {
    if (isNaN(+label)) {
      console.log('lab', label);
      if (label === '&laquo; Previous' && this.page > 1) {
        this.page -= 1;
      } else if (label === 'Next &raquo;' && this.page < this.Posts.last_page) {
        this.page += 1;
      }
    } else {
      this.page = +label;
    }

    this.getArticles();
  }

  save() {
    console.log('update article', this.Post.data);
    this.Post.saving = true;
    this.articleService.update$(this.Post.data.id, this.Post.data).subscribe((response) => {
      console.log('update response', response);
      this.Post.saving = false;
    });
  }

  addMedia() {
    console.log('addMedia', this.mediaUrls);
    this.addingMedia = true;
    this.articleService.addMedia$(this.Post.data.id, {content: this.mediaUrls}).subscribe((response) => {
      console.log('addMedia$ response', response);
      if (response.data.media) {
        this.Post.data.media = [...this.Post.data.media, ...response.data.media];
        this.mediaUrls = '';
        this.addingMedia = false;
      }

    });
  }

  removeMedia(index) {
    const media = this.Post.data.media[index];
    console.log('removeMedia', media);
    this.articleService.removeMedia$(media.type, media.id).subscribe((response) => {
      this.Post.data.media = this.Post.data.media.filter((item) => item.id !== media.id);
      console.log('removeMedia$ response', response);
    });
  }

  onFilesSelected(event) {
    console.log('onFilesSelected', event.target.files);
    this.selectedFiles = event.target.files;
  }

  uploadFile() {
    console.log('uploadFile');
    if (this.selectedFiles.length < 1) { return; }
    this.uploadingFiles = true;
    const formData: FormData = new FormData();
    for (const k in this.selectedFiles) {
      if (this.selectedFiles[k]) {
        const file = this.selectedFiles[k];
        formData.append('files[]', file);
      }
    }

    this.articleService.addMedia$(this.Post.data.id, formData).subscribe((response) => {
      console.log('addMedia$ response', response);
      if (response.data.media) {
        this.Post.data.media = [...this.Post.data.media, ...response.data.media];
        this.uploaderEl.nativeElement.value = '';
        this.uploadingFiles = false;
      }

    });

  }


}
