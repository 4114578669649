import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import { Lightbox } from 'ngx-lightbox';
import {ArticleService} from '../../../../services/article.service';
import {AuthService} from '../../../../services/auth.service';
import { ClipboardService } from 'ngx-clipboard';
import {ArticleModel} from '../../../../_models/article/article.model';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
export class BlogComponent implements OnInit {
  id: number | null = null;
  Posts: {
    data: ArticleModel[],
    current_page?: number,
    last_page?: number,
    from?: number,
    links?: any[]
  } = {data: []};
  page = 1;

  filters: {
    category: string,
    tag: string,
  } = {
    category: null,
    tag: null,
  };

  loadingPosts = false;
  copied = false;
  savePage = this.articleService.page;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private articleService: ArticleService,
    private route: ActivatedRoute,
    private _lightbox: Lightbox,
    private clipboardService: ClipboardService,
  ) {

  }

  Me = this.authService.me$;

  ngOnInit() {
    console.log('ngOnInit');
    // Catch and combine both URL and Query parameter changes and pull the posts according to that
    const posts$ = combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        map((params) => Object.assign({}, params[0], params[1])),
        switchMap((params) => {
          if (params) {
            // console.log('Parameters: ', params);
            this.id = +params?.id || null;
            if (this.id && this.id > 0) {
              return this.getPost();
            } else {
              this.filters.category = params['category-slug'] || null;
              this.filters.tag = params['tag-slug'] || null;
              this.page = params.page || this.page;
              return this.getPosts();
            }
          }
        })
      );

    posts$.subscribe((response: any) => {
      // console.log('Posts', response);
      this.loadingPosts = false;
      if (this.id) {
        this.Posts = {data: [response.data]};
      } else {
        this.Posts = response;
      }
    });


  }

  getPosts() {
    this.loadingPosts = true;
    return this.articleService.list$(
      {
        page: this.page,
        category: this.filters.category || '',
        tag: this.filters.tag || ''});
  }

  getPost() {
    return this.articleService.get$(this.id);
  }

  openLightbox(index: number, files): void {
    const album: {
      src: string,
      caption?: string,
      thumb: string
    }[] = [];
    if (files) {
      for (const k in files) {
        if (files[k]) {
          const file = files[k];
          album.push({
            src: '/api/files/preview?uuid=' + file.uuid + '&size=large',
            thumb: '/api/files/preview?uuid=' + file.uuid + '&size=thumb'
          });
        }

      }
    }
    this._lightbox.open(album, index);
  }

 closeLightbox(): void {
    this._lightbox.close();
  }

  copyToClipboard(text: string) {
    this.clipboardService.copy(text);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1500);
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });

  }

}
