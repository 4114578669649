import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BlogComponent} from './components/pages/blog/blog.component';


const routes: Routes = [
  { path: '', component: BlogComponent },
  { path: ':id', component: BlogComponent },
  { path: 'categories/:category-slug', component: BlogComponent },
  { path: 'tags/:tag-slug', component: BlogComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogRoutingModule { }
