import {Component, Input, OnInit} from '@angular/core';
import {Lightbox} from 'ngx-lightbox';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  constructor(
    private _lightbox: Lightbox,
  ) { }

  @Input() media: {category: string, value: string, provider?: string, mime?: string}[];
  @Input() key = 0;

  ngOnInit(): void {
  }

  openLightbox(index: number, medias): void {
    const album: {
      src: string,
      caption?: string,
      thumb: string
    }[] = [];
    if (medias) {
      for (const k in medias) {
        if (medias[k]) {
          const media = medias[k];
          album.push({
            src: media.value + '&size=large',
            thumb: media.value + '&size=thumb'
          });
        }

      }
    }

    // open lightbox
    this._lightbox.open(album, index);
  }

  closeLightbox(): void {
    this._lightbox.close();
  }

}
