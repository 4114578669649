import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(value: string, ...args: unknown[]): unknown {
    // console.log('TRUSTED URL', value);
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
