import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(value: string, args?: {format: string}): string {
    // console.log('Moment input', value);
    const format = args?.format || 'YYYY-MM-DD HH:mm:ss';
    return moment(value).format(format);
  }

}
