import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {UserModel} from '../_models/user/user.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private apiService: ApiService,
  ) {
    this.init();
  }

  public me$: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel|null>(null);
  public me: UserModel;

  public isLoggedIn$: Subject<boolean> = new Subject<boolean>();

  init() {
    console.log('INIT - AuthService');
    this.apiService.get('users/me').subscribe((response: {data: UserModel}) => {
      // console.log('Me response', response);
      this.me$.next(response.data);
      this.isLoggedIn$.next(true);
    });

    this.me$.subscribe( (user: UserModel) => {
      this.me = user;
    });
  }

  public login$( email: string, password: string ) {
    return this.apiService.post('login', {email, password}).pipe(
      tap((response: {data: UserModel}) => {
        this.me$.next(response.data);
        this.isLoggedIn$.next(true);
      })
    );
  }

  public register$( data: UserModel ) {
    return this.apiService.post('user', data);
  }

  isLoggedIn(): boolean {
    return !!this.me?.id;
  }

}
