import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpHeaders, HttpClient, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
  ) {
    // Only if some reason we need to add some prefix. Basically BackendProxy interceptor does this
    this.baseUrl = environment.APIEndpoint;

  }

  private formatErrors(error: HttpErrorResponse, test) {
    console.log('formatErrors', error, test);
    return  throwError(error);
  }

  get(path: string, params: any = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (params instanceof HttpParams) {
          parameters = params;
        } else if (typeof params === 'object') {
          parameters = new HttpParams({fromObject: params});
        }
        // console.log('apiService get()',`${this.baseUrl}${path}`);
        return this.http.get(`${this.baseUrl}${path}`, { params: parameters, withCredentials: true });
  }

  post(path: string, body: any = {}): Observable<any> {
    return this.http.post(
      `${this.baseUrl}${path}`,
      body,
      {}
    ).pipe(catchError(this.formatErrors));
  }

  put(path: string, body: any = {}): Observable<any> {
    return this.http.put(
      `${this.baseUrl}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  delete(path, params: any = new HttpParams()): Observable<any> {
    let parameters: HttpParams = new HttpParams();
    if (params instanceof HttpParams) {
      parameters = params;
    } else if (typeof params === 'object') {
      parameters = new HttpParams({fromObject: params});
    }

    return this.http.delete(
      `${this.baseUrl}${path}`, { params: parameters }
    ).pipe(catchError(this.formatErrors));
  }

  upload(files, args: any= {}) {
    if (files.length > 0) {
      const formData = new FormData();
      for (const fk in files) {
        // formData = new FormData();
        const file = files[fk];
        formData.append('files[' + fk + ']', file.file, file.file.name);
        if (file.location_id) {
          formData.append('args[' + fk + '][location_id]', file.location_id);
        }
        if (file.desc) {
          formData.append('args[' + fk + '][desc]', file.desc);
        }
        if (args.user_id && args.user_id === 'null') {
          formData.append('args[' + fk + '][user_id]', 'null');
        }
      }

      return this.http.post('/api/files/upload', formData);

    }

    return of(false);
  }


}
