import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'youtube'
})
export class YoutubePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(str: string, ...args: unknown[]): unknown {
    this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/');
    str = str.replace(
      /(?:https?:\/\/)(?:www\.)?youtube.com\/watch\?(?:.*?)v=(.*?)(&|$)$/igm,
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')
    return str;
  }

}
